html,body{
  font-size: 0.85vw;
  background-color: #1A1A1A;
  scroll-behavior: smooth;
}

.App{
  height: auto;

}
