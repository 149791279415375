.anime-page-container {
    height: 100vh;
    width: 100%;
    background: url('https://i.pinimg.com/originals/2e/f6/06/2ef60676f23ba8c52f35520b3526ea7c.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    display: flex;
    flex-direction: column;
    // background: linear-gradient(to bottom, #000428, #004e92);
    // align-items: center;
}

.anime-page-title-text {
    margin-top: 10vh;
    color: aliceblue;
    font-size: 4rem;
    font-weight: bold;
}

.anime-movie-container {
    width: 100%;
    display: flex;
    overflow: hidden;
    object-fit: contain;
    // justify-content: space-around;
    // justify-content: space-evenly;
    margin-top: 150px;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-x: scroll;
}

.anime-movie-container::-webkit-scrollbar {
    margin-top: 0;
    width: 20px;
}

/* Track */
.anime-movie-container::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
    // backdrop-filter: blur(0px);
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.anime-movie-container::-webkit-scrollbar-thumb {
    background: linear-gradient(#283048, #859398);
    border-radius: 10px;
}

/* Handle on hover */
.anime-movie-container::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#283048, #859398);
}


$color1: white;
$color2: black;

.anime-title-h1 {
	background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/6963bbf342d87b3a2150bd8f59682b89.jpg);
  	-webkit-background-clip: text;
	background-size: contain;
	width: 100%;
	text-align: center;
	color: transparent;
	font-weight: 900;
	
	
	&::before {
		content: attr(data-heading);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		background: linear-gradient(45deg, rgba(255,255,255,0) 45%,rgba(255,255,255,0.8) 50%,rgba(255,255,255,0) 55%,rgba(255,255,255,0) 100%);
		-webkit-background-clip: text;
		color: transparent;
		mix-blend-mode: screen;
		animation: shine 1s infinite;
		background-size: 200%;
		text-shadow: 
			2px 2px 10px rgba(#000, 0.2),
			-2px 2px 10px rgba(#000, 0.2),
			-2px -2px 10px rgba(#000, 0.2);
	}
}

@keyframes shine {
	0% {background-position: -100%;}
	100% {background-position: 100%;}
}





/* Not needed for demo */

@font-face {
	font-family:'frozen'; 
	src:url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/Ice%20kingdom%20-%20Bold%20-%20Por%20Kustren.woff');
}


.anime-title-h1 {
    
	top: 0%;
	left: 0%;
	// transform: translate(-50%, -50%);
	position: absolute;
	font-size: 8vw;
	margin: 0;
    margin-top: 10vh;
	font-family: 'frozen', serif;
	font-weight: 700;
}