$button-color: #E50914;

.button-container {
  width: 400px;
//   margin: 0 auto;
//   margin-top: 150px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  padding-top: 20px;
  font-size: 1.2rem;
}

.icon {
  display: inline-block;
  background: $button-color;
  width: 60px;
  height: 60px;
  color: white;
  margin-left: -3px;
  margin-right: -3px;
  transition: all 0.5s;
  cursor: pointer;
  .share-span {
    padding-top: 22px;
    opacity: 0;
    transition: all 0.5s;
  }
}
.first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.last {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.share-label {
  position: relative;
  top: -40px;
  color: white;
  font-weight: bold;
  pointer-events: none;
  transition: all 0.5s;
}

.share:hover {
   .icon {
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    .share-span {
      opacity: 1;
    }
  }
  .share-label {
    opacity: 0;
  }
  .fb {
    background: #3b5998;
  }
  .twtr {
    background: #55acee;
  }
  .linkd {
    background: #007bb5;
  }
  .gplus {
    background: #dd4b39;
  }
}
