.navbar-container{
    position: fixed;
    display: flex;
    z-index: 999;
    height: 10vh;
    width: 100%;
    margin-top: 0;
    color: aliceblue;
    // background: linear-gradient(-360deg, transparent 0%, rgba(0,0,0,0.50) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,.7) 10%,transparent);
    transition: background-color 1s ;
    transition-duration: 1s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: background-color;
    align-items: center;
    justify-content: center;
    align-content: center;
    // box-shadow: inset 0 38px 36px -18px #141414;
    // background-color: transparent;
    // background-color: aliceblue;
}

.navbar-container-left{
    display: flex;
    position: absolute;
    left: 0px;
    height: 10vh;
    // padding: 20px 15px 20px 15px;
    padding-left: 20px;
    width: 88%;
    align-items: center;
    // background-color: aqua;
}

.navbar-text{
    padding: 20px;
    font-size: 1.5rem;
    text-decoration: none;
    color: aliceblue;
    display: flex;
}

.navbar-title{
    color: #E50914;
    font-size: 2.5rem;
}


.navbar-container-right{
    width: 12%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0px;
    align-items: center;
    // justify-content: center;
    align-content: center;
    // padding: 20px 15px 20px 15px;
    // background-color: bisque;
}
  
.scroll {
    // background: transparent;
    background-color: #141414;
    // transition: all 1.5s linear;
    // opacity: 0.1;
    // box-shadow: 0 0 25px 5px #000;
}


.bell-icon{
    height: 4vh;
    padding: 20px;
}

.search-icon-navbar{
    background-color: rgb(133, 86, 30);
}
.navbar-title{
    font-family: Montserrat;
}
.navbar-title span{
    color: blanchedalmond;
}