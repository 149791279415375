.dashboard-container{
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // margin: 0;
    // padding: 0;
    // background: #0E0E0E;
    // font-family: 'Work Sans', sans-serif;
    // align-items: center;
}

.dashboard-container-top-top{
    display: flex;
    background-color: #131313;
}

.dash-image-overlay{
  position: absolute;
  height:100vh;
  width:100%;
  z-index: 2;
  opacity: 0.7;
  background: linear-gradient(to left, rgba(42,159,255,.2) 0%,#131313 60%,#141414 100%);
  background-blend-mode: multiply;
}

.dashboard-container-top{
    height: 100vh;
    // background-image: url('https://images.unsplash.com/photo-1513106580091-1d82408b8cd6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80');
    // background-image: url('https://images.unsplash.com/photo-1489599849927-2ee91cede3ba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #131313;
    color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    // background-color: aquamarine;
}

.dashboard-container-middle{
    margin-top: -100px;
    // height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-container-bottom{
    // height: 120vh;
    // height: 80vh;
    z-index: 3;
    // background-color: aqua;
    display: flex;
    flex-direction: column;
}

.dashboard-title-text{
    // padding-left: 40px;
    // font-size: 4rem;
    margin-top: 20px;
    color: #484848;
    font-size: 50px;
    font-weight: bold;
    font-family: monospace;
    letter-spacing: 7px;
    cursor: pointer;
    color: #fff;
    z-index: 99;
    text-shadow: 0 0 10px #fff,
                 0 0 20px #fff, 
                 0 0 40px #fff;
    // color:  #E50914;
    // text-shadow: 4px 4px 1px black;
    // box-shadow: inset 0 8px 6px 6px black;
}

.dashboard-title-text span{
    transition: .5s linear
  }
  .dashboard-title-text:hover span:nth-child(1){
    margin-right: 5px
  }
  .dashboard-title-text:hover span:nth-child(1):after{
    content: "'";
  }
  .dashboard-title-text:hover span:nth-child(2){
    margin-left: 30px
  }
  .dashboard-title-text:hover span{
    color: #fff;
    text-shadow: 0 0 10px #fff,
                 0 0 20px #fff, 
                 0 0 40px #fff;
  }

.dashboard-subtitle-text{
    padding-left: 80px;
    font-size: 3rem;
    color: aliceblue;
    // background-color: aqua;
    margin-bottom: 0;
    margin-top: 10vh;
    // color:  #E50914;
    // text-shadow: 4px 4px 1px black;
    // box-shadow: inset 0 8px 6px 6px black;
}

.dashboard-description-text{
    width: 70%;
    line-height: 35px;
    padding-left: 40px;
    padding-top: 30px;
    font-size: 2rem;
    text-align: justify;
    font-weight: bold;
    z-index: 99;
}

.chevron{
    // padding-top: 40vh;
    color: #fff;
    font-size: 3rem;
    cursor: pointer;
    z-index: 98;
  }

  .chevron:hover{
    color: blanchedalmond;
  }

  .drop{
    z-index: 99;
  }


  
.section-2-h1 {
  font-size: 9rem;
  color: #fff;
  margin: 0;
  letter-spacing: 10px;
  // background-color: #479197;
}

.section-2-h2{
  font-size: 20px;
  letter-spacing: 4px;
  color: #fff;
  position: absolute;
  text-align: justify;
  top: 200px;
  left: 30px;
  margin: 0;
  line-height: 30px;
}

svg {
  width: 16px;
  height: 16px;
}

svg path {
  fill: #fff;
}


.mh {
  height: 400px;
}

.section-2 {
  margin-top: 28px;
  position: relative;
}

.section-3 {
  margin-top: 28px;
  position: relative;
  height: 250px;
}

.navbar {
  display: flex;
  width: 318px;
  justify-content: space-around;
  position: absolute;
  right: 40px;
  top: 15px;
}

.navbar p {
  text-transform: uppercase;
  color: #C2C2C2;
  font-size: 14px;
}

.hamburger-button {
  margin: 6px 0;
}

.hamburger-button span {
  display: block;
  width: 30px;
  height: 2px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  background: #D2D2D2;
  transition: all .8s cubic-bezier(0.250, 0.100, 0.250, 1.000);
}

.hamburger-button span:nth-of-type(2) {
  width: 20px;
}

.box {
  width: 235px;
  height: 208px;
  z-index: 400;
}

img {
  width: 235px;
}

.section-1 .box {
  position: absolute;
  scale: (1.2);
}

.section-1 .box:nth-child(odd) {
  left: 0;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  top: 3px;
}

.section-1 .box:nth-child(even) {
  top: 16px;
  left: 146px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.section-1 .box:nth-child(3) {
  left: 293px;
}

.section-1 .box:nth-child(4) {
  left: 441px;
}

.section-1 .box:nth-child(5) {
  left: 589px;
}

.section-2 .box {
  position: absolute;
  top: 0;
  scale: (1.2);
}

.section-2 .box:nth-child(odd) {
  left: 146px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.section-2 .box:nth-child(even) {
  left: 293px;
  top: 16px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.section-2 .box:nth-child(3) {
  left: 441px;
}

.section-3 .box {
  position: absolute;
  top: 0;
  left: 247px;
}

.section-3 .box:nth-child(odd) {
  left: 293px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.section-3 .box:nth-child(even) {
  left: 441px;
  top: 16px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.section-3 .box:nth-child(3) {
  left: 586px;
}

.section-3 .box:nth-child(4) {
  left: 730px;
}

.company-name {
  position: absolute;
  right: -9px;
  top: -91px;
}

.social-icons {
  list-style: none;
  display: flex;
  width: 200px;
  justify-content: space-around;
  position: absolute;
  bottom: 140px;
/*   change 140px to 40px when not coding in codepen */
  right: 40px;
}

.navigation-icons {
  display: flex;
  width: 20px;
  justify-content: space-between;
  position: absolute;
  top: 36%;
  flex-direction: column;
  height: 200px;
  align-items: center;
  left: 20px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.circle.one {
  background: #fff;
}

.slide-number {
  position: absolute;
  bottom: 55px;
  left: 8px;
  color: #AEAEAE;
  transform: rotate(90deg);
  font-size: 14px;
}

.slide-text {
  width: 268px;
  position: absolute;
  bottom: 40px;
  left: 60px;
}

.slide-text p > span {
  margin: 8px;
  vertical-align: text-top;
}

.slide-text svg {
  width: 22px;
  height: 22px;
}

.slide-text svg path {
  fill: #479197;
}

.slide-text p {
  color: #B2B2B2;
  margin-bottom: 8px;
}

.slide-text p + span {
  color: #b0b0b0;
  font-size: 12px;
}

.section-2-h1 span{
  color: #E50914;
}