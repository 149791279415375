.genres-card-container{
    // height: auto;
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  overflow: hidden;
//   background-color: aquamarine;
  margin-top: 0;
  padding-top: 0;
}

.genre-title{
  color: aliceblue;
}

.genres-container {
    display: flex;
    justify-content: center;
    width: 90vw;
    // background-color: antiquewhite;
    margin-top: 0;
    padding-top: 0;
  }
  
  .genre-panel {
    box-shadow: 0 5px 1rem rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    flex: 0.5;
    margin: 10px;
    position: relative;
    transition: flex 0.5s ease-in;
  }
  
  .genre-panel h3 {
    font-size: calc(24 / 16 * 3rem);
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    opacity: 0;
    color: #fff;
  }
  
  .genre-panel.active {
    flex: 5;
  }
  
  .genre-panel.active h3 {
    opacity: 1;
    transition: all 0.5s ease-in 0.4s;
  }
  
  @media (max-width: 480px) {
    .genres-container {
      width: 100vw;
    }
  
    .genre-panel:nth-of-type(4),
    .genre-panel:nth-of-type(5) {
      display: none;
    }
  }