.romantic-comedy-page-container {
  height: 100vh;
  width: 100%;
  background: url('https://c4.wallpaperflare.com/wallpaper/924/822/393/artistic-fantasy-couple-landscape-wallpaper-preview.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #ffd8d8;
}


.romantic-page-top-container{
  display: flex;
}

#couple {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  -webkit-filter: saturate(2);
  filter: saturate(2);
}

#hearts {
  -webkit-animation: heartpulse 4s infinite;
  animation: heartpulse 4s infinite;
}

@keyframes heartpulse {
  0% {
    fill: #8c2d49;
  }

  50% {
    fill: #8e485d;
    scale: 1.1;
    -webkit-transform: scale(1.1, 1.1);
  }

  100% {
    fill: #a872a8;
  }
}


.romantic-comedy-page-title-text {
  margin-top: 10vh;
  color: #8c2d49;
  color: aliceblue;
  font-size: 4rem;
  font-weight: bold;
  color: #fff;
}

.romantic-gif{
  // background-color: aqua;
  margin-top: 10vh;
}

.romantic-movie-container {
  width: 100%;
  display: flex;
  margin-top: 10vh;
  // justify-content: space-around;
  // justify-content: space-evenly;
  // margin-top: 40px;
  // margin-bottom: 10vh;
  overflow: hidden;
  object-fit: contain;
  z-index: 10;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-x: scroll;
}

.romantic-movie-container::-webkit-scrollbar {
  margin-top: 0;
  width: 20px;
}

/* Track */
.romantic-movie-container::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  // backdrop-filter: blur(0px);
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.romantic-movie-container::-webkit-scrollbar-thumb {
  background: linear-gradient(#283048, #859398);
  border-radius: 10px;
}

/* Handle on hover */
.romantic-movie-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(#283048, #859398);
}


.movie-link {
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css?family=Luckiest+Guy&display=swap");


// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-family: "Luckiest Guy", cursive;
// }

.romatic-title-h1 {
  margin: 0;
  font-size: 5rem;
  padding: 0;
  color: white;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  .romantic-title-span {
    transform: scale(0.9);
    display: inline-block;
  }
  .romantic-title-span:first-child {
    animation: bop 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite
      alternate;
  }
  .romantic-title-span:last-child {
    animation: bopB 1s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
      infinite alternate;
  }
}

@keyframes bop {
  0% {
    transform: scale(0.9);
  }
  50%,
  100% {
    transform: scale(1);
  }
}

@keyframes bopB {
  0% {
    transform: scale(0.9);
  }
  80%,
  100% {
    transform: scale(1) rotateZ(-3deg);
  }
}

