.dashboard-container-middle{
    // margin-top: -100px;
    // height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chevron{
    // padding-top: 40vh;
    color: #fff;
    font-size: 8rem;
    cursor: pointer;
    z-index: 99;
    // background-color: aqua;
  }

  .chevron:hover{
    color: blanchedalmond;
  }