@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;900&display=swap');



// .container  {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   height: 400px;
//   width: 600px;
//   background: white;
//   overflow: hidden;
//   border-radius: 20px;
//   cursor: pointer;
//   box-shadow: 0 0 20px 8px #d0d0d0;
// }

// .card-container{
//     height: 30vh;
//     width: 200px;
//     border: 2px solid whitesmoke;
//     padding: 50px;
//     background: url('https://i.pinimg.com/originals/03/5a/1e/035a1e61ba9e4b1fd767bf8066c6f45e.jpg');
//     background-repeat: no-repeat;
//     background-size: cover;
//     margin-bottom: 10vh;
// }

// .imdbRatingPlugin{

// }

// .rating-star-icon{
//     background-color: yellow;
// }

.container {
    // position: absolute;
    height: auto;
    width: 100%;
    top: 60px;
    margin-bottom: 10vh;
    left: calc(50% - 300px);
    display: flex;
  }
  
  .card1 {
    display: flex;
    height: 63vh;
    width: 420px;
    z-index: 5;
    background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000;
  /*   margin-left: -50px; */
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
  }

  .card2 {
    display: flex;
    height: 63vh;
    width: 420px;
    z-index: 5;
    background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000;
  /*   margin-left: -50px; */
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
  }

  .card3 {
    display: flex;
    height: 63vh;
    width: 420px;
    z-index: 6;
    background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000;
  /*   margin-left: -50px; */
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
  }

  .card4 {
    display: flex;
    height: 63vh;
    width: 420px;
    z-index: 7;
    background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000;
  /*   margin-left: -50px; */
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
  }
  
  .card1:not(:first-child) {
      margin-left: -150px;
  }
  
  .card1:hover {
    transform: translateY(-20px);
    transition: 0.4s ease-out;
  }
  
  .card1:hover ~ .card1 {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
  }

  .card2:not(:first-child) {
    margin-left: -150px;
}

.card2:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.card2:hover ~ .card2 {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.card3:not(:first-child) {
  margin-left: -150px;
}

.card3:hover {
transform: translateY(-20px);
transition: 0.4s ease-out;
}

.card3:hover ~ .card3 {
position: relative;
left: 50px;
transition: 0.4s ease-out;
}

.card4:not(:first-child) {
  margin-left: -150px;
}

.card4:hover {
transform: translateY(-20px);
transition: 0.4s ease-out;
}

.card4:hover ~ .card4 {
position: relative;
left: 50px;
transition: 0.4s ease-out;
}
  
  .title {
    color: white;
    font-weight: 300;
    position: absolute;
    left: 20px;
    top: 15px;
  }
  
  .bar {
    position: absolute;
    top: 100px;
    left: 20px;
    height: 5px;
    width: 150px;
  }
  
  .emptybar {
    background-color: #2e3033;
    width: 100%;
    height: 100%;
  }
  
  .filledbar {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 0px;
    height: 100%;
    background: rgb(0,154,217);
    background: linear-gradient(90deg, rgba(0,154,217,1) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
    transition: 0.6s ease-out;
  }
  
  .card:hover .filledbar {
    width: 120px;
    transition: 0.4s ease-out;
  }
  
  .circle {
    position: absolute;
    top: 150px;
    left: calc(50% - 60px);
  }
  
  .stroke {
    stroke: white;
    stroke-dasharray: 360;
    stroke-dashoffset: 360;
    transition: 0.6s ease-out;
  }
  
  svg {
    fill: #17141d;
    stroke-width: 2px;
  }
  
  .card:hover .stroke {
    stroke-dashoffset: 100;
    transition: 0.6s ease-out;
  }