.movie-movie-container{
    height: auto;
    width:100%;
    background-color: #141414;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-container{
    height:100vh;
    width:100%;
    background-image: url('https://images.unsplash.com/photo-1536183922588-166604504d5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8ZGFyayUyMG5pZ2h0fGVufDB8fDB8fA%3D%3D&w=1000&q=80');
    background-size: cover;
    background-repeat: none;
    display: flex;
    flex-direction: column;
    color: white;
}

.image-overlay{
    position: absolute;
    height:100vh;
    width:100%;
    z-index: 2;
    opacity: 0.85;
    background: linear-gradient(to left, rgba(42,159,255,.2) 0%,#131313 60%,#141414 100%);
		background-blend-mode: multiply;
}

.dropmenu-icon{
    z-index: 99;
}

.movie-all-texts{
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    padding-right: 70px;
    z-index: 98;
    // background-color: aqua;
}

.movie-title-and-imdb-rating1{
    height: 30vh;
    width: 100%;
    display: flex;
    // background-color: aqua;
    align-items: flex-end;
}

.movie-title-on-main-movie-page1{
    color: white;
    display: flex;
    // background-color: aquamarine;
    // align-items: center;
    // align-items: flex-end;
    // padding-left: 70px;
    // justify-content: center;
    // width: 60%;
    font-size: 3rem;
}

.movie-details-on-main-movie-page{
    color: aliceblue;
    padding-top: 20px;
    font-size: 1.5rem;
    // padding-left: 70px;
}

.movie-description-on-main-movie-page{
    width: 50%;
    padding-top: 20px;
    font-size: 1.3rem;
    text-align: justify;
}

.more-about-movie-on-main-movie-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 3vh;
    padding-top: 20px;
    // background-color: beige;
}

.imdbRatingPlugin1{
    // width: 50%;
    font-size: 3rem;
    color: white;
    display: flex;
    margin-left: 30px;
    justify-content: flex-end;
    // justify-content: end;
    // height: 40vh;
    // align-items: flex-end;
    // padding-right: 70px;
    // padding: 70px;
}

.imdbRatingPlugin1 h6{
    margin: 0;
    height: 5vh;
}

.imdbRatingPlugin1 img{
    height: 5vh;
    padding-right: 20px;
    width: 70px;
}

.more-meta-data-movie-page{
    width: 100%;
    // height: 10vh;
    display: flex;
    line-height: 4vh;
    // justify-content: space-between;
    text-align: justify;
    font-size: 1.3rem;
    // background-color: #0f79af;
}

.left-column-movie-page{
    width: 15%;
    // background-color: aqua;
}

.right-column-movie-page{
    width: 50%;
    // background-color: aquamarine;
}

.movie-play-button-container{
    width: 100%;
    height: 10vh;
    margin-top: 20px;
    // padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: aquamarine;
}

.movie-play-left-container{
    display: flex;
    align-items: center;
}

.movie-play-button{
    height: 8vh;
    width: 110px;
    border-radius: 3px;
    background-color: #0f79af;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    font-size: 1.4rem;
    box-shadow: 2px 2px 2px 2px #3E3B39;
    cursor: pointer;
}

.movie-share-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.movie-trailer-button-movie-page{
    height: 6vh;
    width: 200px;
    border-radius: 3px;
    background-color: black;
    opacity: 0.7;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    font-size: 1.4rem;
    cursor: pointer;
    border: 3px solid #ffffff;
}

.movie-trailer-button-movie-page:hover{
    border-color: #2a9fff;
	color: #2a9fff;
}

.play-icon{
    height: 4vh;
}

.more-text-container{
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    // padding:20px;
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    color: aliceblue;
    width: 90%;
}

.more-movie-movie-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.movie-movie-cards-container{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}

.star-movie-icon{
    color: orange;
    font-size: 2.5rem;
    margin-left: 15px;
}