.movie-movie-container{
    height: auto;
    width:100%;
    background-color: #141414;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-container{
    height:98vh;
    width:100%;
    background-image: url('https://images.unsplash.com/photo-1536183922588-166604504d5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8ZGFyayUyMG5pZ2h0fGVufDB8fDB8fA%3D%3D&w=1000&q=80');
    background-size: cover;
    background-repeat: none;
    display: flex;
    flex-direction: column;
    color: white;
}

.movie-all-texts{
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    padding-right: 70px;
    // background-color: aqua;
}

.movie-title-and-imdb-rating{
    height: 30vh;
    width: 100%;
    display: flex;
    // background-color: aqua;
}

.movie-title-on-main-movie-page{
    color: white;
    display: flex;
    // align-items: center;
    align-items: flex-end;
    // padding-left: 70px;
    // justify-content: center;
    width: 50%;
    font-size: 3rem;
}

.movie-details-on-main-movie-page{
    color: aliceblue;
    padding-top: 20px;
    font-size: 1.5rem;
    // padding-left: 70px;
}

.movie-description-on-main-movie-page{
    width: 50%;
    padding-top: 20px;
    font-size: 1.3rem;
    text-align: justify;
}

.more-about-movie-on-main-movie-page{
    line-height: 3vh;
    padding-top: 20px;
}

.imdbRatingPlugin{
    width: 50%;
    font-size: 3rem;
    color: white;
    display: flex;
    justify-content: flex-end;
    // height: 40vh;
    align-items: flex-end;
    // padding-right: 70px;
    // padding: 70px;
}

.imdbRatingPlugin h6{
    margin: 0;
    height: 5vh;
}

.imdbRatingPlugin img{
    height: 5vh;
    padding-right: 20px;
    width: 100px;
}

.more-meta-data{
    width: 40%;
    display: flex;
    // justify-content: space-between;
    text-align: justify;
    font-size: 1.3rem;
}

// .left-column{
//     width: 50%;
//     // background-color: aqua;
// }

// .right-column{
//     width: 50%;
//     text-align: left;
//     // background-color: aquamarine;
// }

.movie-play-button-container{
    width: 100%;
    height: 10vh;
    margin-top: 20px;
    // padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: aquamarine;
}

.movie-play-left-container{
    display: flex;
    align-items: center;
}

.movie-play-button{
    height: 8vh;
    width: 110px;
    border-radius: 3px;
    background-color: #0f79af;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    font-size: 1.4rem;
    box-shadow: 2px 2px 2px 2px #3E3B39;
    cursor: pointer;
}

.movie-share-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.movie-trailer-button{
    height: 6vh;
    width: 110px;
    border-radius: 3px;
    background-color: black;
    opacity: 0.7;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    font-size: 1.4rem;
    cursor: pointer;
}

.play-icon{
    height: 4vh;
}

.share-icon{
    height: 5vh;
    padding-left: 30px;
    cursor: pointer;
}

.more-text-container{
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    // padding:20px;
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    color: aliceblue;
    width: 90%;
}

.more-movie-movie-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.movie-movie-cards-container{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}

.right-column-cast{
    width: 50vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: left;
}

.card1-container-movie{
    z-index: 4;
    transition: all 0.5s linear;
}

.card2-container-movie,.card3-container-movie,.card4-container-movie{
    margin-left: -150px;
    z-index: 5;
    transition: all 0.5s linear;
}

.card1-container-movie:hover,.card2-container-movie:hover,.card3-container-movie:hover,.card4-container-movie:hover{
    // margin-left: -150px;
    z-index: 9;
    transform: scale(1.1);
}
