
.action-page-container {
    height: 100vh;
    width: 100%;
    background: url('https://images3.alphacoders.com/137/137657.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}

.action-page-title-text {
    margin-top: 10vh;
    color: aliceblue;
    font-weight: bold;
    font-size: 4rem;
    z-index: 10;
    animation-duration: 2s;
}

.action-movie-container {
    width: 100%;
    display: flex;
    // justify-content: space-around;
    // justify-content: space-evenly;
    margin-top: 50px;
    overflow: hidden;
    object-fit: contain;
    z-index: 10;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-x: scroll;
    animation-duration: 2s;
}

.action-movie-container::-webkit-scrollbar {
    margin-top: 0;
    width: 20px;
}

/* Track */
.action-movie-container::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
    // backdrop-filter: blur(0px);
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.action-movie-container::-webkit-scrollbar-thumb {
    background: linear-gradient(#283048, #859398);
    border-radius: 10px;
}

/* Handle on hover */
.action-movie-container::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#283048, #859398);
}
